

.hero-banner{
	.overlay{
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0px;
		top: 0px;
		background: linear-gradient(71.75deg, rgba(0, 0, 0, 0.2) 10.16%, rgba(0, 0, 0, 0) 86.65%), linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04));
		z-index: 10;
	} 
} 