@mixin center($x: "c") {
  position: absolute;
  @if $x == "y" {
    top: 50%;
    transform: translateY(-50%);
  } @else if $x == "x" {
    left: 50%;
    transform: translateX(-50%);
  } @else {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@mixin placeholder() {
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    @content;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    @content;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    @content;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    @content;
  }
}

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}
