.swup-transition-fade {
	transition: 0.4s;
	opacity: 1;
}

html.is-animating .swup-transition-fade {
	opacity: 0;
}
@responsive {
	.fade-in {
		animation: fade-in 0.3s ease both;
	}

	.fade-out {
		animation: fade-out 0.3s ease both;
	}
}
.slideUpFade {
	animation: slideUpFade 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) both;
}

.animation--spinner {
	animation: spin 1s linear both infinite;
}

@keyframes pulse-circle {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1) translate(-50%, -50%);
		opacity: 0.7;
	}
	100% {
		-webkit-transform: scale(3);
		transform: scale(3) translate(-50%, -50%);
		opacity: 0;
	}
}

@keyframes fade-in {
	0% {
		display: none;
		visibility: hidden;
		opacity: 0;
	}
	1% {
		display: block;
		visibility: visible;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fade-up {
	0% {
		display: none;
		visibility: hidden;
		opacity: 0;
		transform: translateY(75px);
	}
	1% {
		display: block;
		visibility: visible;
	}
	100% {
		transform: translateY(0px);
		opacity: 1;
	}
}

@keyframes show {
	0% {
		display: none;
		visibility: hidden;
		opacity: 0;
	}
	1% {
		display: block;
		visibility: visible;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fade-out {
	0% {
		display: block;
		visibility: visible;
		opacity: 1;
	}
	99% {
		display: block;
		visibility: visible;
		opacity: 0;
	}
	100% {
		display: none;
		visibility: hidden;
	}
}

@keyframes gFadeIn {
	from,
	20%,
	40%,
	60%,
	80%,
	to {
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes gFadeOut {
	from,
	20%,
	40%,
	60%,
	80%,
	to {
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.ggfadeIn {
	animation: gFadeIn 0.4s ease-out both;
}

.ggfadeOut {
	animation: gFadeOut 0.4s ease-out both;
}

.goverlay {
	background: rgba(0, 0, 0, 0.7) !important;
}
.gbtn.disabled {
	display: none;
}
.glightbox-clean .gclose,
.glightbox-clean .gnext,
.glightbox-clean .gprev {
	opacity: 1;
	transition: opacity 0.2s ease;
	background-color: transparent !important;

	&:hover {
		opacity: 0.8;
	}
}

.lds-ripple {
	display: inline-block;
	width: 80px;
	height: 80px;
	@extend .center;
}
.lds-ripple div {
	position: absolute;
	border: 4px solid #000;
	opacity: 1;
	border-radius: 50%;
	animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
	animation-delay: -0.5s;
}
@keyframes lds-ripple {
	0% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 1;
	}
	100% {
		top: 0px;
		left: 0px;
		width: 72px;
		height: 72px;
		opacity: 0;
	}
}
@keyframes spin-centered {
	0% {
		transform: rotate(0deg) translate(-50%, -50%);
		transform-origin: left top;
	}
	100% {
		transform: rotate(360deg) translate(-50%, -50%);
		transform-origin: left top;
	}
}

@keyframes fade-swap-in {

	0%{
		top:0;
		left: 0;
		opacity: 0;
	}
	99%{
		position: absolute;
	}
	100%{
		position: relative;
		opacity: 1;
	}

}

@keyframes fade-swap-out {

	0%{
		top:0;
		left: 0;
		opacity: 1;
		position: absolute;
		display: block;
	}
	100%{
		position: relative;
		opacity: 0;
		display: none;
	}

}

.fade-swap-in{
	animation: fade-swap-in 0.3s 0.25s ease both;
}
.fade-swap-out{
	animation: fade-swap-out 0.3s ease both;
}

.fade-enter-active {
	top: 0;
	left: 0;
	transition: opacity 0.2s ease;
	transition-delay: 0.3s;
}

.fade-leave-active {
	position: absolute;
	transition: opacity 0.2s ease;
}
.fade-leave-to,
.fade-enter {
	opacity: 0;
}

.group-enter-active {
	opacity: 1;
	transition: opacity 0.2s ease 0.2s;
}
.group-enter-active,
.group-leave-active {
	transition: opacity 0.2s ease;
	width: 100%;
}
.group-enter-to {
	position: absolute;
	opacity: 0;
}
.group-leave-to,
.group-enter {
	opacity: 0;
}
