@keyframes fullWidth {
  0% {
      width: 0%;
  }
  
 100% {
       width: 100%;
  } 
}


.glide--fade{
	.glide__slides {transform:none !important; width:100% !important; display:block;}
	.glide__slide {position:absolute; left:0; top:0; opacity:0; width:100% !important; transition:opacity 1s;}
	.glide__slide:first-child {position:relative;}
	.glide__slide--active {z-index:1; opacity:1;}
}


.glide--hero{ 
  &:after{
    content: "";
    height: 200px;
    width: 100%;
    transform: rotate(-180deg);
    background-image: linear-gradient(180deg, rgba(4,2,25,0.50) 0%, rgba(4,2,25,0.00) 100%);
  }
}


.glide--list{

}


.glide__bullets{

  position: relative;

  .glide__bullet{
    background-color: transparent;
    width: 10px;
    height: 10px;
    padding: 0;
    border-radius: 50%;
    border: 1px solid #0087D6;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    cursor: pointer;
    line-height: 0;
    -webkit-box-shadow: 0 .25em .5em 0 rgba(0,0,0,.1);
    box-shadow: 0 .25em .5em 0 rgba(0,0,0,.1);
    margin: 0 .25em;

    &.glide__bullet--active{
      background-color: #0087D6;
    }
  } 
}

.glide--images{
  .glide__bullets {
    @apply w-full; 
    position: absolute;
    text-align: right;
    transition: .3s opacity;
    z-index: 10; 
    bottom: -45px;
    right: 0; 
 
    .glide__bullet {
        @apply my-25 mx-5;
        background: #CECED1;
        border-radius: 0;
        width: 32px;
        height: 2px;

        &:before{
          @apply h-full w-full block bg-offBlack; 
          
          width: 32px;
          height: 2px; 
          content: ""; 
        } 

      &.glide__bullet--active {
          &:before{
            animation-name: fullWidth;
            animation-duration: 3s; 
            animation-iteration-count: once;  
            transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
            animation-fill-mode: both;
          }
        }
    }
  } 
}


.glide--hero{
  .glide__bullets {
    @apply w-full; 
    position: absolute;
    text-align: right;
    transition: .3s opacity;
    z-index: 10; 
    bottom: 0;
    right: 3%; 
 
    .glide__bullet {
      	@apply my-25 mx-5;
      	 background: #CECED1;
      	border-radius: 0;
      	width: 32px;
      	height: 2px;

      	&:before{
        	 @apply h-full w-full block bg-offBlack; 
          background-color: #ffffff; 
        	width: 32px;
        	height: 2px; 
        	content: ""; 

      	}

     	&.glide__bullet--active {
	        &:before{
	          
            animation-name: fullWidth;
            animation-duration: 3s; 
            animation-iteration-count: once;  
            transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
            animation-fill-mode: both;
	        }
      	}
    }
  }  
}


.buttons-container {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    justify-content: space-between;
    width: 100%; 
    padding: 0 3%;
    button{
      height: 100%;
      svg{
        width: 40px;
      }
      &:hover{
        svg{
            fill: #FF2D00; 
        }
      }
    } 
}

.gclose.gbtn{
    position: absolute;
    right: 3%;
    top: 16px;
    width: 50px;
    svg{
      width: 50px; 
    }
    &:hover{ 
      svg{
          fill: #FF2D00;
      }
    }
}

.glightbox-mobile .glightbox-container .gslide-description {
    background: transparent;
  
}


.glightbox-container,.gslider {
  .gclose{
     path{ 
      fill: #fff;
    }
    &:hover{
      path{
        fill: #0087D6;
      }
    }
  }

  .gnext {
     path{
      fill: #fff;
    }
    &:hover{
      path{
        fill: #0087D6;
      }
    }
  }

  .gprev {
     path{
      fill: #fff;
    }
    &:hover{
      path{
        fill: #0087D6;
      }
    }
  }
}

