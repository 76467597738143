
.tile-shadow{
	@apply opacity-100 transition-all duration-500;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 20.84%), linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
}

.tile-shadow__hover{
	&:hover{
		.tile-shadow{
			@apply opacity-0;
		} 
	}
}
 
 
#wrapper {
  position:relative;
  overflow-x: hidden;
  z-index: 0;
}

#content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow:hidden;
  top: 1500px;
  background-color: rgba(230, 218, 230, .5);
  padding: 25px;
  box-sizing: border-box;
 box-shadow: 100px 5px 25px #333; 
}



#paralxheading {
	transform: translateY(-0%);
	margin-top: -10%;
}

#paralxtext {
	transform: translateY(-0%);
}
#paralx2 {
	transform: translateY(-0%);
}
