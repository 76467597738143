.btn {
	@apply text-15
	px-20 
	py-10
	text-center 
	font-larsseitReg
	duration-200 
	transition-colors 
	items-center 
	justify-between;
	max-width: 100%;
	
	border-radius: 50px;
	line-height: 18px;
	display: flex;
    width: fit-content;
	.arrow{
		svg{
			@apply duration-200 transition-all;
			margin-right: 0px;
		}
	}

	&:hover,
	&:focus {
		outline: 0;
		box-shadow: none; 
		.arrow{ 
			svg{
				transform: translateX(5px);
			} 
		}
	}
	& + .btn {
		@apply mt-20;
		@screen sm {
			@apply ml-20 mt-0;
		}
	}
	@screen sm {
		display: inline-flex;
    	width: fit-content;
	}
	@screen xs {
    	width: fit-content;
	}
	&[disabled='true'],
	&[disabled='true']:hover,
	&[disabled='true']:focus {
		opacity: 0.5 !important;
	}
	&.loading {
		color: transparent !important;
		position: relative;
		&:after {
			content: '';
			opacity: 1;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			border: 2px white solid;
			border-right: 2px transparent solid !important;
			animation: spin-centered 2s infinite both linear;
			display: block;
			position: absolute;
			left: 50%;
			top: 50%;
		}
	}
	&[disabled='true'],
	&[disabled='true']:hover,
	&[disabled='true']:focus {
		@apply opacity-50;
	}


}

.btn--primary {
	@apply bg-white border-grey border-1 text-black text-body;
	&:hover,
	&.loading,
	&.active {
		@apply text-white bg-grey;
		&:after {
			@apply border-grey;
		} 
	}
}

.btn--inverse {
	@apply text-white bg-white border-white border-1;
	&:hover,
	&.loading,
	&.active {
		@apply bg-bg text-white;
		&:after {
			@apply border-white;
		}
	}
}

.btn--green {
	@apply bg-green text-white border-1 border-green;
	&:hover,
	&.loading {
		@apply text-green bg-white border-white;
		svg{
			path{
				fill: #0087D6;
			}
		}
		&:after {
			@apply border-white;
		}
	}
}

.btn--white {
	@apply bg-white text-green border-1 border-white;
	svg{
		path{
			fill: #014E86;
		}
	}
	&:hover,
	&.loading {
		@apply text-white bg-green border-green;
		svg{
			path{
				fill: #ffffff;
			}
		} 
		&:after {
			@apply border-white;
		}
	}
} 

.btn--pill {
	@apply bg-transparent text-white border-1 border-white;
	font-size: 14px;
	padding: 5px 10px;
	border-radius: 15px;
	min-width: 0;

	&:hover,
	&.loading,
	&.active {
		@apply bg-bg text-white;
		&:after {
			@apply border-white;
		}
	}
}

.btn--tab {
	@apply px-sm text-white relative;
	min-width: 0;
	&:hover,
	&.active, 
	&.loading {
		@apply text-green;
	}
}
