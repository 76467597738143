.js-popout {
  @apply fixed h-screen overflow-y-auto top-0 w-500 max-w-full;
  right: 0;
  z-index: 900;
  transform: translateX(105vw);
  transition: transform 0.3s ease;
  @screen md {
    @apply w-1/2;
    transform: translateX(55vw);
  }
  &.active {
    transform: translateX(0);
  }
  button svg path { 
    stroke: currentColor;
  }
}

.js-backdrop {
  background: rgba(black, 0.5);
  animation: fade-in 0.3s ease both;
  z-index: 50;
  @apply fixed w-full h-full opacity-60 top-0 left-0;
  &.close {
    animation: fade-out 0.3s ease both;
  }
}

.js__spinner {
  height: 25px;
  width: 25px;
  border: 1px solid black;
  border-right: 1px solid transparent;
  border-radius: 50%;
}
.js-notification {
  z-index: 100;
  button svg path {
    stroke: currentColor;
  }
}
.js-notification--bar {
  z-index: 60;
  transition: all 0.2s ease;
  display: none;

  a {
    text-decoration: underline;
  }
}

[v-cloak] {
  display: none;
}

.gscrollbar-fixer {
  position: relative;
  padding-right: 17px !important;
  margin-right: 0 !important;
  // #main-nav{
  // 	padding-right: 12px;
  // }
}
.inline-video__placeholder {
  animation: fade 0.3s ease-out both;

  &.hide {
    animation: fade-out 0.3s ease-out both;
    .play-button{
      opacity: 0;
    }
  }
} 