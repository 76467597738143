


.tile-blackwhite__hover{
	&:hover{
		.tile-blackwhite{
			img{
				-webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
	  			filter: grayscale(0%);
			}
		} 
	}
	.tile-blackwhite{
		img{
			@apply transition-all duration-500;
			-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  			filter: grayscale(100%);
		}
	}
} 

.tile-zoom__hover{
	&:hover{ 
		.tile-zoom{
			img{
				transform: scale(1.1);
			}
		} 
	}
	.tile-zoom{
		img{
			transform: scale(1);
		}
	}
} 
