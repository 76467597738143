* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	@apply text-body;
}

p {
	@apply font-larsseitThin text-body leading-standard;
	a {
		text-decoration: underline;
		opacity: 1;
		transition: opacity 0.2s ease;
		&:hover {
			opacity: 0.7;
		}
	}
}
em {
	font-size: inherit;
}
.heading {
	@apply font-larsseitThin leading-standard;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	@apply font-larsseitThin;
}
.h0 {
	@apply text-mob-h0  leading-standard font-larsseitThin;
	@screen md {
		@apply text-h0; 
		line-height: 95px;
	}
}
h1,
.h1 {
	@apply text-mob-h1  leading-standard font-larsseitThin;
	@screen md {
		@apply text-h1;
		line-height: 55px;
	}
}
h2,
.h2 {
	@apply text-mob-h2  leading-standard font-larsseitThin;
	@screen md {
		@apply text-h2;
		line-height: 35px;
	}
}
h3,
.h3 {
	@apply text-mob-h3  leading-standard;
	@screen md {
		@apply text-h3;
		line-height: 20px;
	}
}

h4,
.h4 {
	@apply text-mob-h4  leading-standard;
	@screen md {
		@apply text-h4;
		line-height: 15px;
	}
}

h5,
.h5 {
	@apply text-h5  leading-22 text-black;
	@screen md {
		@apply text-h5;
		line-height: 10px;
	}
}

h6,
.h6 {
	@apply text-h6  leading-standard ;
	@screen md {
		@apply text-h6;
		line-height: 27.5px;
	}
} 

.body-copy,
.body-text {
	@apply font-larsseitThin text-body;
	line-height: 23.4px;
	@screen md { 
		@apply text-body;
		line-height: 19.5px; 
	}
}

.footer-text {
	@apply font-larsseitThin text-body;
	line-height: 23.4px;
	@screen md { 
		@apply text-17;
		line-height: 20.5px; 
	}
} 
 
.large-text {
	@apply font-larsseitThin text-20;
	line-height: 26px;
}

.lead-text {
	@apply font-larsseitThin text-24;
	line-height: 35px;
	@screen md {
		@apply text-lead;
		line-height: 35px;
	} 
}

.small-text {
	@apply font-larsseitReg text-small uppercase;
	line-height: 19.5px;
	letter-spacing: 1px;
}

.tiny-text {
	@apply font-larsseitReg text-12 uppercase;
	line-height: 15.6px; 
	letter-spacing: 1px;  
}

dd {
	@apply mb-sm;
	&.last {
		@apply -mt-sm;
	}
}

.link {
	@apply text-body underline;

	&--styled-underline {
		@apply no-underline text-body relative transition-opacity duration-200;
		padding-bottom: 5px;

		&::after {
			content: '';
			height: 1px;
			width: 100%;
			position: absolute;
			left: 0;
			bottom: 0;
			@apply bg-bg;
		}
		& {
			&::after {
				@apply bg-white;
			}
		}
		&:hover {
			opacity: 0.7;
		}
	}
}

.markdown {


	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		@apply mb-30;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		@apply;
	}
	h4 {
		font-size: 30px;
	}
	p {
		@apply mb-sm;
		&:last-of-type {
			@apply mb-rg;
		}
		@screen xxl {
			@apply text-lead;
		}
	}
	&--reduced-spacing {
		li,
		p {
			&:last-of-type {
				@apply mb-sm;
			}
		}
	}
	&--restrict-headings {
		h2 {
			max-width: 200px;
			@screen xxl {
				@apply max-w-290;
			}
		}
	}
	&--no-spacing {
		li,
		p {
			&:last-of-type {
				@apply mb-0;
			}
		}
	}
	&--spacing-consistent {
		p {
			@apply mb-rg;
			&:last-of-type {
				@apply mb-30;
			}
			@screen md {
				@apply mb-rg;
			}
		}
	}
	&--italic-u {
		em {
			font-style: normal;
			position: relative;
			&:after {
				content: '';
				height: 1px;
				width: 100%;
				@apply bg-bg block absolute left-0;
				bottom: -5px;
			}
		}
	}
	p,
	li {
		font-size: inherit;
		font-family: inherit;
		line-height: inherit;
	}

	a { 
		font-size: inherit;
		font-family: inherit;
		line-height: inherit;
		transition: all 0.2s ease; 
		&.no-underline {
			@apply no-underline;
		}
		&:hover {
			@apply text-green;
		}
	}
	ol:not(.styled-list),
	ul:not(.styled-list) {
		@apply pl-sm mb-sm;
		list-style: revert;
	}
}

.bold,
a,
em,
strong,
b {
	font-size: inherit;
	font-family: inherit;
	line-height: inherit; 
}
.bold,
strong,
b {
  @apply;
}

.small-caps {
	@apply text-small tracking-small;
}
