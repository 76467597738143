
footer {
	.btn {
		min-width: 0px;
	}
	.markdown {
		a {
			text-decoration: none;
		}
	}

	.bg-logo{
		svg{
			@apply h-full;
		}
	}
}

.home-logo{ 
	svg{
		@apply h-full w-full; 
	}
}