
.expand-collapse__expander,
.js-dropdown__container{
	opacity: 0;
    height: 0;
	&[aria-expanded='false']{
		@apply transition-all duration-500 pb-0;
	}
	&[aria-expanded='true']{
		@apply transition-all duration-500 pb-xs; 
	}

	&.open { 
	    opacity: 1;
	    height: 100%; 
	}
	&.isanimateout {
	    opacity: 0 !important;
	    height: 0 !important;
	}
	&.isanimate {
	    opacity: 1;
	    height: 100%; 
	}
} 
 



.expand-collapse__toggle{
	.icon{
		opacity: 0;
	}

	&:hover{
		.icon{
			opacity: 1;
		}
	}

	&.active-hover{
		& + .expand-collapse__expander{
			@apply relative transition-all duration-500;
		} 
	}
}
