// map modal on register page

.modal {
  &.modal-visible {
    opacity: 1;

  }
}

.js-backdrop {
  background: rgba(50,50,50,0.7);
  transition: 950ms cubic-bezier(0.76, 0, 0.24, 1);
  @apply fixed w-full h-full z-40 top-0 left-0;
  &.close {
    transition: 950ms cubic-bezier(0.76, 0, 0.24, 1);
  }
}
 

.fade-in-enter-active,
.fade-in-leave-active,
.fade-in-move {
  transition: 950ms cubic-bezier(0.76, 0, 0.24, 1);
  transition-property: opacity, transform;
}

.fade-in-enter {
  opacity: 0;
}

.fade-in-enter-to {
  opacity: 1;
}

.fade-in-leave-active, 
.fade-in-leave-to {
  opacity: 0;
}

 

.slide-in-enter-active,
.slide-in-leave-active,
.slide-in-move {
  transition: 950ms cubic-bezier(0.76, 0, 0.24, 1);
  transition-property: opacity, transform;
}

.slide-in-enter {
  transform: translateX(50%);
  opacity: 0;
}

.slide-in-enter-to {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-leave-active {
  opacity: 0;
}

.slide-in-leave-to { 
  transform-origin: center top;
  transform: translateX(50%); 
  opacity: 0;
}



.team-modal-sm{

  .modal {
    @apply right-0 px-40 pt-80 pb-40;  
    background-color: #202020; 
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    right: 0;
    bottom: 0;
    width: 100vw;  
    height: 100%;

    position: fixed;
    z-index: 999;
    transition: 950ms cubic-bezier(0.76, 0, 0.24, 1);
    
    @screen md {
      @apply px-80 pt-100 pb-80;
      width: 40vw;
    }
  }
}


.team-modal{

  .modal {
    @apply right-0 px-40 pt-80 pb-40;  
    background-color: #E5E5E5;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    right: 0;
    bottom: 0;
    width: 100vw;  
    height: 100%;

    position: fixed;
    z-index: 999;
    transition: 950ms cubic-bezier(0.76, 0, 0.24, 1);
    
    @screen md {
      @apply px-80 pt-100 pb-80;
      width: 40vw;
    }
  }
}

