@import 'glightbox/dist/css/glightbox';
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core"; 	

//Type
@import 'typography/font-loading';
@import 'typography/typography';

// helpers
@import 'utilities/mixins';
@import 'utilities/animation';
@import 'utilities/grid';
@import 'utilities/buttons';  
@import 'utilities/globals';

@import 'components/form';
@import 'components/nav'; 
@import 'components/footer'; 
@import 'utilities/hero';

@import 'components/glideinit'; 
@import 'components/expand-collapse'; 
@import 'components/modal';  
@import 'components/js-classes'; 

@import 'pages/home';
@import 'pages/work'; 