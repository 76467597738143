* {
	-webkit-tap-highlight-color: transparent;
	overflow-anchor: none;
}

*:focus {
	outline: none;
}

*::selection {
	background: black;
	color: #fff;
} 

body,
html {
	@apply font-larsseitReg font-normal text-black;
	width: 100%;
	font-size: 10px;
	

	&.scrolling {
		scroll-behavior: smooth;
	}
	@screen sm {
		.theme-white {
			cursor: url('https://ewr1.vultrobjects.com/construkt/cursor-black.svg'), auto;	
			*{
				cursor: url('https://ewr1.vultrobjects.com/construkt/cursor-black.svg'), auto;	
			}	
		}

		.theme-offBlack{
			cursor: url('https://ewr1.vultrobjects.com/construkt/cursor-white.svg'), auto;	 
			*{
				cursor: url('https://ewr1.vultrobjects.com/construkt/cursor-white.svg'), auto;	 
			}	
		} 
	}
}

button {
	&:focus { 
		box-shadow: 0;
		outline: 0 !important;
	}
}

[data-src] {
	transition: all 0.5s ease;
	transform-origin: center;
}
.lazyload,
.lazyloading {
	transform: scale(1.1);
	filter: blur(50px); 
}
.lazyloaded {
	filter: blur(0px);
	transform: scale(1); 
}
@responsive {
	.center-y {
		@include center('y');
	}
	.center-x {
		@include center('x');
	}
	.center {
		@include center();
	}
}

.play-button {
	cursor: pointer;
	
	&:hover,
	a:hover & {
		svg{
			*{
				fill:#20934D;
			}
		}
	} 
	svg{
		width: 30px;
	    padding-left: 1px;
	}
}

/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}
input[type='search']::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	display: none;
}

.parallax-section {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	@screen md {
		background-attachment: fixed;
	}
}

.general-hover {
	@apply opacity-100 transition-all duration-500;
	&:hover {
		@apply opacity-50;
	}
	&--light {
		@extend .general-hover;
		&:hover {
			@apply opacity-80;
		}
	}
}


.green-hover {
	transition: all 0.5s ease; 
	*{
		transition: all 0.5s ease;
	}
	&:hover {
		@apply text-green #{!important}; 
		*{
			svg{
				fill: #FF2D00; 
			}
		}
	}
}


.card-hover {
	img{
		@apply transition-all duration-500;
	}
	&:hover{
		img{
			transform: scale(1.1);
		}
	}
}

.flex-table {
	display: flex;
	flex-direction: column;
	border-collapse: collapse;
	thead {
		display: none;
	}
	tr {
		@apply flex flex-col border-b-1 border-black pt-15;
	}
	th {
		@apply text-small font-normal;
		&:first-of-type {
			@apply pl-0;
		}
		&:last-of-type {
			@apply pr-0;
		}
	}
	td {
		@apply flex-col py-5 justify-start;
		&:first-of-type {
			@apply pl-0;
		}
		&:last-of-type {
			@apply pr-0;
		}
	}
	th,
	td {
		flex: 1;
		&[colspan='0'] {
			flex: 0;
		}
		&[colspan='2'] {
			flex: 2;
		}
		&[colspan='3'] {
			flex: 3;
		}
		&[colspan='4'] {
			flex: 4;
		}
	}
	@screen md {
		thead {
			@apply w-full text-small block;
		}
		tr {
			@apply flex-row pt-0;
		}
		th,
		td {
			@apply flex px-xs py-xs;
			min-width: 120px;
		}
	}
}

.slideout-open {
	overflow: hidden;
}

.swiper--fade {
	@apply h-full;
	.swiper-container {
		@apply h-full;
	}
	.swiper-slide {
		height: auto;
	}
	.swiper-pagination-bullet {
		height: 2px;
		width: 22px;
		border-radius: 0;
		background-color: white;
	}
}

.contact-grid {
	@screen md {
		grid-template-rows: max-content min-content;
	}
	.markdown {
		a {
			text-decoration: none;
		}
	}
}

#peopleSection {
    margin-top: -60px;
    padding-top: 80px;
}
