#main-nav {
	@apply w-full flex flex-wrap items-start;

	transition: all 0.5s ease; 

	.main-nav__wrap{
		transition: all 0.5s ease;
	} 


	.logo {
		width: 184px;
	}

	.nav__link{ 
		letter-spacing: 1px;  
	}
}

.nav__logo { 
	display: block;
	overflow: hidden;
	width: 100%;
}

.theme-white {
	.nav-trigger{ 
		&::after, 
		&::before{
			
			background: #4F4B48;
		
		}
	}
		
}

.theme-offBlack{
	.nav-trigger{ 
		&::after, 
		&::before{
			
			background: #fff;
		
		}
	}
}

.nav-trigger{ 
	width: 30px;
	height: 10px;
	position: relative;


	&:focus {
		outline: 0;
		box-shadow: none;
	}

	&::after, 
	&::before{
		content:'';
		width: 30px;
		height: 2px;
		display: block;
		background: #fff;
		position: absolute;
		left:0;
		transition:all 0.2s ease-out;
		transform: rotate(0deg) transform(0,0);
	}

	&::after{
		top: 0;
		transform-origin: center
	}
	&::before{
		top: 10px;
		transform-origin: center
	}
}


.nav-trigger {
	z-index: 99;
	&::before {
		transition: transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19),
		background ease 0.15s;
	}

	&::after {
		transition: transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19),
		background ease 0.15s;
	}
	
	&.is-active {
		&::before {
			top:5px;
			transform: rotate(-45deg);
			transition: transform 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
			background: white;
		}

		&::after {
			top:5px;
			transform: rotate(45deg);
			transform-origin: center;
			transition:transform 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
			background: white;
		}
	}
}

.nav-backdrop{ 
    opacity: 0;
    pointer-events: none;
    height: 100vh;
    width: 100vw; 
    position: fixed;
    left: 0; 
    top: 0;
    background: rgba(0,0,0,0.4);
    z-index: 3; 
    transition: all 0.2s ease-out;
   
} 

@media ( max-width: 768px ){ 

.theme-white {
	#main-nav {  
		.main-nav__bar {
			@apply bg-white text-darkGrey; 
		}
	}
}

.theme-offBlack{
	#main-nav {  
		.main-nav__bar {
			@apply bg-offBlack text-white; 
		}
	}
}

	#main-nav {  
		z-index: 50;

		.main-nav__bar {

			@apply top-0 relative; 
			position: fixed;
			top: 0;
			z-index: 4;
			flex-direction: column;
			display: none;
			// opacity: 0;
			visibility: hidden;
			width: 100vw; 
			height: 100vh;

			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start; 
			right: -100%; 
			@screen lg {
				right: -50%; 
				width: 50vw;
			}

			 

		}
		.main-nav__dropdown {
			@apply bg-bg;
			position: fixed;
			width: 100%;
			height: 100vh;
			z-index: 100;
		}
		& .main-nav__list {
			width: 100%;
		}  

		&.is-open {
			.main-nav__bar {
				display: flex;
				visibility: visible;
				opacity: 1;
				right: 0%;
				transition: all 0.2s 0.1s ease-out;
			}
		
			.nav-backdrop{
			    opacity: 1;
			}
		
		}
		&.is-animating {
			.main-nav__bar {
				transition: all 0.2s ease-out;
				display: flex; 
				visibility: visible;
			}
		}
	
	}
	.nav-trigger {
		display: block;
	}
}
 
 #main-nav{
 	
 	&.bg-transparent{
		transition: all 0.5s ease; 
		.nav-trigger {
			transition: all 0.5s ease;  
		}
		.nav__link { 
			transition: all 0.5s ease; 
		}
		.nav__logo{ 
			svg{
				path{
					transition: all 0.5s ease; 
				}
			}
		}
	}
}

.theme-white{
	.nav-sticky {
		transition: all 0.5s ease; 
		*{
			transition: all 0.5s ease;
		}
		@apply bg-white;   
	}  
}

.theme-offBlack{
	.nav-sticky {
		transition: all 0.5s ease; 
		*{
			transition: all 0.5s ease;
		}
		@apply bg-offBlack;   
		
	}  
}

.nav-sticky {
	.main-nav__wrap{
		padding-top: 15px !important;
		padding-bottom: 15px !important;  
	}
}  


[data-sticky='always'] {
	& + * {
		margin-top: 50px;
		@screen lg {
			@apply mt-nav;
		}
	}
}
// .nav-sticky--hide {
// 	transform: translateY(-100px);
// }
.dropdown {
	a {
		transition: 0.2s 0s ease opacity;
	}
	&.open {
		a {
			transition: 0.2s 0.4s ease opacity;
		}
	}
}
